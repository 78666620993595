import * as React from "react";
import { PageProps } from "gatsby";

import smmIcon from "src/images/services/fullSmm.svg";
import ServicePage from "src/routes/ServicesPages";
import Seo from "src/components/Seo";

const items = [
  "Аналитика присутствия в социальных сетях",
  "Разработка стратегии продвижения",
  "Production контента",
  "Модерация сообществ",
  "Работа с блогерами и лидерами мнений",
  "Проведение рекламных кампаний",
];

const workStages = [
  "Брифинг с клиентом ",
  "Подготовка коммерческого предложения ",
  "Разработка smm-стратегии и контент-плана",
  "Разработка и согласование фирменного стиля социальной сети и тональности коммуникации, написание текстов ",
  "Публикация согласно контент-плану",
  "Подготовка отчета по работе в периоде",
  "Анализ выполненной работы и составление рекомендаций",
];

const SmmPage: React.SFC<PageProps> = () => {
  return (
    <div>
      <Seo
        pageTitle="SMM"
        pageDescription="SMM для бизнеса. Ведем социальные сети Instagram, Вконтакте, Facebook. Повышаем охваты. Запускаем рекламу. Приводим клиентов."
      />
      <ServicePage
        workStages={workStages}
        header="SMM"
        img={smmIcon}
        listItems={items}
      />
    </div>
  );
};

export default SmmPage;
